import { A } from 'hookrouter';
import { useEffect } from 'preact/hooks';

import * as styles from './page.module.css';

import { DEFAULT_TITLE, DEFAULT_DESCRIPTION, PAGES } from '../constants';
import logo from '../assets/journalous.svg';
import rsIcon from '../assets/Icon-b.svg';

function Page({ mainClass, title, description, children }) {
  const year = new Date().getFullYear();

  useEffect(() => {
    document.title = title || DEFAULT_TITLE;
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', description || DEFAULT_DESCRIPTION);
  }, [title, description]);

  return (
    <>
      <header class={styles.header}>
        <div class={styles.wrap}>
          <div class={styles.logoBox}>
            <A href={PAGES.HOME}>
              <img src={logo} alt="Journalous logo" />
            </A>
          </div>
          <nav class={styles.nav}>
            <ul>
              <li>
                <a href="https://forms.gle/Jd5Xqf4h5ksSeTdv9" target="_blank">
                  Sign Up For Beta
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <main class={mainClass}>{children}</main>
      <footer class={styles.footer}>
        <div class={styles.wrap}>
          <p>
            &copy; {year}{' '}
            <a class={styles.rsIcon} href="https://retrosquare.com">
              <img
                alt="Retrosquare LLC"
                title="Copyright Retrosquare LLC"
                src={rsIcon}
              />
            </a>{' '}
            <A href={PAGES.TERMS}>Terms of Use</A> |{' '}
            <A href={PAGES.PRIVACY}>Privacy Policy</A> |{' '}
            <A href={PAGES.DISCLAIMER}>Disclaimer</A>{' '}
          </p>
        </div>
      </footer>
    </>
  );
}

export default Page;
