.hero {
  padding: 2em;
  background: var(--paragraph-background-color);
  border-radius: 20px;
}

.hero p {
  color: var(--paragraph-color-on-color);
  font-family: var(--heading-font);
  font-size: 2em;
  margin: 0;
}

.layoutGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
  column-gap: 2em;
  row-gap: 5em;
  margin: 2em;
}

.hero {
  grid-column: 1 / 6;
}

.featureDescription {
  grid-column: 1 / 4;
  align-self: center;
}

.featureImage {
  grid-column: 4 / 6;
  width: 100%;
  align-self: center;
}

.featureDescription:nth-of-type(2n) {
  grid-column: 3 / 6;
}

.featureImage:nth-of-type(2n) {
  grid-column: 1 / 3;
  width: 100%;
}

.desc1 {
  order: 0;
}
.image1 {
  order: 1;
}
.image2 {
  order: 2;
}
.desc2 {
  order: 3;
}
.desc3 {
  order: 4;
}
.image3 {
  order: 5;
}
.image4 {
  order: 6;
}
.desc4 {
  order: 7;
}
.desc5 {
  order: 8;
}
.image5 {
  order: 9;
}

@media screen and (max-width: 550px) {
  .layoutGrid {
    display: block;
  }

  .hero p {
    font-size: 1.5em;
  }

  .desc1,
  .image1,
  .image2,
  .desc2,
  .desc3,
  .image3,
  .image4,
  .desc4,
  .desc5,
  .image5 {
    order: 0;
  }

  .featureImage {
    margin-top: 4em;
  }

  .featureDescription {
    margin-top: 2em;
  }
}
