import { useRoutes } from 'hookrouter';

import Home from './pages/Home';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Disclaimer from './pages/Disclaimer';

const routes = {
  '/': () => <Home />,
  '/terms': () => <Terms />,
  '/privacy': () => <Privacy />,
  '/disclaimer': () => <Disclaimer />,
};

const App = () => {
  const routeResult = useRoutes(routes);
  return routeResult;
};

export default App;
