import Page from '../components/Page';

import freedom from '../assets/freedom.svg';
import images from '../assets/images.svg';
import impressions from '../assets/impressions.svg';
import moods from '../assets/moods.svg';
import tools from '../assets/tools.svg';

import * as styles from './home.module.css';
import * as common from './common.module.css';

function Home() {
  return (
    <Page>
      <>
        <div class={common.wrap}>
          <div class={styles.layoutGrid}>
            <section class={styles.hero}>
              <p>
                Journalous is an anti-social smart journal where you can write
                about your day without sharing it with the rest of the internet.
              </p>
            </section>
            <img
              class={`${styles.featureImage} ${styles.image1}`}
              alt=""
              src={moods}
            />
            <div class={`${styles.featureDescription} ${styles.desc1}`}>
              <h2>Mood Tracking</h2>
              <p>
                Create custom moods and use them to track how you feel each day
                as you write in your journal. Find correlations between what you
                did and how it made you feel during the day. Use these trends to
                help you make future decisions about hobbies, employment, or
                even eating habits.
              </p>
            </div>
            <img
              class={`${styles.featureImage} ${styles.image2}`}
              alt=""
              src={impressions}
            />
            <div class={`${styles.featureDescription} ${styles.desc2}`}>
              <h2>Daily Impressions</h2>
              <p>
                Write short, concise summaries of your day and lock them to
                prevent them from being edited later. Each day add to your
                streak of impressions! Of course, if your summary 10 years ago
                really ends up being cringey enough to delete, you can convert
                your impression to a normal post, but you'll lose your
                streak--so be careful what you write!
              </p>
            </div>
            <img
              class={`${styles.featureImage} ${styles.image3}`}
              alt=""
              src={images}
            />
            <div class={`${styles.featureDescription} ${styles.desc3}`}>
              <h2>Images, Images, Images</h2>
              <p>
                A picture is worth a thousand words and we're all about telling
                stories. Upload as many pictures as you want and allow our
                picture analysis models try to determine the sentiments found in
                the pictures, the picture quality, and more!
              </p>
            </div>
            <img
              class={`${styles.featureImage} ${styles.image4}`}
              alt=""
              src={tools}
            />
            <div class={`${styles.featureDescription} ${styles.desc4}`}>
              <h2>Statistics &amp; Tools to Help You Journal Daily</h2>
              <p>
                Keep tabs on fun journaling statistics like: What is the length
                of your average entry? Which day do you write the most on? What
                is your daily impression streak? What is your entry streak? And
                how do all of these things correlate with each other? Also,
                opt-in to daily journaling reminders to help you remember to
                record the events of your day.
              </p>
            </div>
            <img
              class={`${styles.featureImage} ${styles.image5}`}
              alt=""
              src={freedom}
            />
            <div class={`${styles.featureDescription} ${styles.desc5}`}>
              <h2>Import/Export Data Freely</h2>
              <p>
                Your journal is your data. We built Journalous because we
                thought there needed to be a place to write personal stuff
                without the eyes of the internet reading it. If you ever decide
                to take your data somewhere else or just want to back it up in
                your personal archives: we offer tools to allow you to export
                your data in an open, standard JSON format that is human
                readable and able to easily be used in other applications (you
                can even build your own). Of course, if you ever need to
                re-import your data back into Journalous you can do that too.
                Importing data from Facebook and Twitter will also be available
                to capture past years of your life.
              </p>
            </div>
          </div>
        </div>
      </>
    </Page>
  );
}

export default Home;
