export const PAGES = {
  HOME: '/',
  TERMS: '/terms',
  PRIVACY: '/privacy',
  DISCLAIMER: '/disclaimer',
};

export const DEFAULT_TITLE = 'Journalous';
export const DEFAULT_DESCRIPTION =
  'Journalous is an anti-social smart journal where you can write about your day without sharing it with the rest of the internet.';
