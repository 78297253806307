.wrap {
  max-width: 960px;
  margin: 0 auto;
}

.logoBox > a > img {
  height: 42px;
}

.header {
  height: 72px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.footer {
  height: 58px;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  color: var(--nav-link-color);
}

.header .wrap {
  display: grid;
  grid-template-columns: 1fr 2fr;
  height: 100%;
  align-items: center;
}

.nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: right;
}

.nav li {
  display: inline-block;
  margin-left: 1em;
  font-size: 22px;
}

.nav a {
  padding: 1em;
  text-decoration: none;
}

.nav a:hover {
  color: var(--nav-hover-link-color);
  background-color: var(--nav-hover-background-color);
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer p {
  margin: 0;
}

.footer a {
  text-decoration: none;
  border-bottom: 1px dotted var(--nav-link-color);
}

.footer .rsIcon > img {
  height: 1.25rem;
  width: 1.25rem;
  display: inline-block;
  background: white;
  vertical-align: sub;
}

.footer .rsIcon {
  border-bottom: none;
}

@media screen and (max-width: 550px) {
  .nav ul,
  .header .wrap {
    display: block;
  }

  .header {
    height: auto;
  }

  .nav ul {
    text-align: center;
    margin-bottom: 1em;
  }

  .nav li:first-child {
    margin-left: 0;
  }

  .nav a {
    padding: 0;
  }

  .logoBox {
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer {
    height: auto;
    padding: 1em;
    text-align: center;
  }

  .footer .wrap {
    width: 70%;
  }
}
